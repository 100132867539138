<template>
  <div class="mlist service ">
    <div id="owl-demo" class="owl-carousel">
      <a class="item">1</a>
      <a class="item">2</a>
      <a class="item">3</a>
      <a class="item">4</a>
      <a class="item">5</a>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Vue3Index',

  data() {
    return {

    };
  },

  mounted() {
    this.$nextTick(() => {
      var dom = $('#owl-demo')
      var sliderApp = $('#owl-demo').owlCarousel({
        center: false,
        items: 3,
        loop: true,
        margin: 0,
        autoWidth: false,
        // nav: false,
        responsive: {},
        smartSpeed: 500,
        dots: true,
        // navText: ['<i class="icon iconfont icon-back"></i>', '<i class="iconfont icon-more"></i>']

      });
      let dotDom = dom.find('.owl-dots');
      let dots = dotDom.find('.owl-dot');
      let numWrapper = '<div class="_dotNumberWrapper">';
      numWrapper += '<div class="_dotsWrapper">'
      dots.each((i, v) => {
        numWrapper += '<div class="_dot">' + getTwoNumber(i + 1) + '</div>'
      });
      numWrapper += '</div>';
      numWrapper += "<div class='_all'>" + getTwoNumber(dots.length + 1) + "</div>"
      numWrapper += '</div>';
      numWrapper = $(numWrapper).appendTo(dom.find('.content_list'));

      numWrapper.find('._dot').eq(0).addClass('active');

      sliderApp.on('changed.owl.carousel', function (event) {
        var item = dom.find('.owl-dots .owl-dot.active').index();
        if ((item + 1) % 2 == 0) {
          $(dom).addClass('even');
        } else {

          $(dom).removeClass('even');
        }
        numWrapper.find('._dot').removeClass('active');
        numWrapper.find('._dot').eq(item).addClass('active');
      });



      function getTwoNumber(num) {
        return num < 10 ? '0' + num : num + '';
      };


    })
  },

  methods: {

  },
};
</script>

<style>
.service.mlist .owl-dots {
  bottom: -20vh;
  left: 10vw;
}

.service.mlist .owl-dots .owl-dot span,
.service.mlist .owl-dots .owl-dot.active span,
.service.mlist .owl-dots .owl-dot:hover span {
  display: inline-block;
  border-radius: 50%;
  margin: 4PX;
}
.service.mlist .owl-dots .owl-dot{
  top: -7px;
}
.service.mlist .owl-dots .owl-dot.active:before{
  left: -55%;
}
</style>
<style scoped>
/* 007aff */
</style>